/* eslint-disable react/no-unused-state */
import React, { useContext, useState, useRef } from "react";
import uuidv4 from "uuid/v4";
import orderApi from "../api/order";
import env from "../config/environment";
import analytics from "../utils/analytics/analytics";
import { getNewSize } from "../utils/size";
import { TrackingDataContext } from "./TrackingDataContext";
import { ICart, ICartItem, IDiscountObject, IShippingObject } from "../interfaces";
import ga4Analytics from "../utils/analytics/ga4Analytics";

export const CART_CONTEXT_STORAGE_KEY = "CART_CONTEXT_STORAGE_KEY";
const { DEFAULT_PRODUCT_TYPE } = env;

export type ICartContext = {
  staticCart: React.MutableRefObject<ICart>;
  currentDesignCartItemId: string | null;
  fetchingCartPricing: boolean;
  fetchingCartPricingError: string;
  paypalScriptLoaded: boolean;
  loadCart: (cartParam: any) => void;
  setPaypalScriptLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  addCartItem: (
    customProps: any,
    cartItemId: string,
    previewImageUrl: string,
    isCanvas: boolean,
    isFramed: boolean,
    isDigital: boolean,
    isPendant: boolean,
    pendantMaterial: string,
    frameColor: string,
    shipping: IShippingObject,
    orderId: string,
    customerId: string,
    isOrderPaid: boolean,
    currency: string | null,
    productPriceCents: number | null,
    callback: ({ updatedCart, newItem }: { updatedCart: ICart; newItem: ICartItem }) => void,
  ) => void;
  addCartItemPlanets: (
    customProps: any,
    cartItemId: string,
    previewImageUrl: string,
    isCanvas: boolean,
    isFramed: boolean,
    isDigital: boolean,
    isPendant: boolean,
    pendantMaterial: string,
    frameColor: string,
    shipping: IShippingObject,
    orderId: string,
    customerId: string,
    isOrderPaid: boolean,
    currency: string | null,
    productPriceCents: number | null,
    callback: ({ updatedCart, newItem }: { updatedCart: ICart; newItem: ICartItem }) => void,
  ) => void;
  removeCartItem: (cartItemId: string, shipping: IShippingObject, orderId: string) => void;
  updateCartItem: (cartItem: ICartItem) => void;
  setCurrentDesignCartItemId: React.Dispatch<React.SetStateAction<string | null>>;
  getAndUpdateCartPricing: (
    shipping: IShippingObject,
    orderId: string,
    includeShippingUpgrades: boolean,
    includeItemUpgrades: boolean,
  ) => Promise<ICart | null>;
  getPricingForCart: (
    shipping: IShippingObject,
    orderId: string,
    previousAmountOff?: number | undefined,
    previousDiscount?: any,
  ) => Promise<boolean>;
  removeCanvasImageFromCartItem: (cartItemId: string) => void;
  clearCartItems: () => void;
  setCartContent: (cartContent: any) => void;
  addFrameToCartItem: (
    cartItemId: string,
    frameColor: string,
    framingService: boolean,
    shipping: IShippingObject,
    isUpsell: boolean | undefined,
    orderId: string,
  ) => ICartItem[];
  addCanvasToCartItem: (cartItemId: string, shipping: IShippingObject, isUpsell: boolean | undefined, orderId: string) => ICartItem[];
  changeFrameColorForCartItem: (cartItemId: string, newFrameColor: string) => void;
  removeDiscountFromCart: (newDiscount?: IDiscountObject | undefined, removedDiscount?: IDiscountObject | undefined) => Promise<ICart>;
  updatePhoneNumber: (phoneNumber: string) => void;
  containsSomePendants: () => boolean;
  containsOnlyPendants: () => boolean;
  clearCartContext: () => void;
  increaseUpsellTotal: (upsellPrice: number) => void;
  setSizeIdForCartItem: (cartItemId: string, sizeId: string) => ICartItem[];
  applyDiscountToCart: (shipping: IShippingObject, orderId: string, newDiscount: IDiscountObject) => Promise<any>;
  shouldSubmitStripePaymentForm: boolean;
  setShouldSubmitStripePaymentForm: React.Dispatch<React.SetStateAction<boolean>>;
};

let CartContext;
// eslint-disable-next-line no-multi-assign
const { Provider, Consumer } = (CartContext = React.createContext({}));

const initCartObject: ICart = {
  productType: DEFAULT_PRODUCT_TYPE,
  // phoneNumber: "",
  items: [],
  // discount: {
  //   code: "",
  //   // amountOff: 0,
  //   // discountType: "",
  //   // discountAmount: 0,
  //   // discountCategory: "",
  //   // appliesTo: "",
  //   // freeShipping: false,
  //   // restrictions: {},
  //   // minRequirements: {},
  //   // stacks: [],
  //   // countryCode: "",
  // },
  // upsellTotal: 0,
};

// class CartProvider extends React.Component<any, any> {
const CartProvider = ({ children }) => {
  // const [cart.current, setCartContents] = useState<ICart>(initCartObject);
  // const [cart, setCartContents] = useState<ICart>(initCartObject);
  const [currentDesignCartItemId, setCurrentDesignCartItemId] = useState<string | null>(null);
  const [fetchingCartPricing, setFetchingCartPricing] = useState<boolean>(false);
  const [fetchingCartPricingError, setFetchingCartPricingError] = useState<string>("");
  const [paypalScriptLoaded, setPaypalScriptLoaded] = useState<boolean>(false);
  const [shouldSubmitStripePaymentForm, setShouldSubmitStripePaymentForm] = useState<boolean>(false);
  const staticCart = useRef<ICart>(initCartObject);

  // Nested Context
  const { trackAddProductToCart, trackFbRemoveProductFromCart } = useContext(TrackingDataContext);

  const setCartContent = (cartContent) => {
    staticCart.current = {
      ...staticCart.current,
      discount: cartContent.discount,
      items: cartContent.items,
      price: cartContent.price,
    };

    // console.log(`CART CONTEXT: setCartContent: staticCart.current.price: ${JSON.stringify(staticCart.current.price, null, 4)}`);
  };

  const clearCartContext = () => {
    setCartContent({ ...initCartObject });
    setCurrentDesignCartItemId(null);
    setFetchingCartPricing(false);
    setFetchingCartPricingError("");
  };

  // When we add an item to the cart for the first time, we want to give it an id.
  // We also want to kick off the thumbnailUrl fetching process so we can display the image in the staticCart.current.
  const addCartItem = (
    customProps: any,
    cartItemId: string,
    previewImageUrl: string,
    isCanvas: boolean,
    isFramed: boolean,
    isDigital: boolean,
    isPendant: boolean,
    pendantMaterial: string,
    frameColor: string,
    shipping: IShippingObject,
    orderId: string,
    customerId: string,
    isOrderPaid: boolean,
    currency: string,
    productPriceCents: number | null,
    callback: ({ updatedCart, newItem }: { updatedCart: ICart; newItem: ICartItem }) => void,
  ): void => {
    // Can't add items to paid orders.
    if (isOrderPaid) {
      return;
    }

    const item: ICartItem = {
      id: cartItemId || uuidv4(),
      copies: 1, // TODO: increment / decremenet functionality in staticCart.current.
      previewImageUrl,
      design: {
        productType: DEFAULT_PRODUCT_TYPE, // isPendant === true ? "pendant" : DEFAULT_PRODUCT_TYPE,
        customProps,
      },
      frameColor: isFramed && frameColor && frameColor !== "" ? frameColor : "",
      isCanvas: isCanvas === true,
      isFramed: isFramed === true,
      isDigital: isDigital === true,
      isPendant: isPendant === true,
      sku: "",
      pendantMaterial,
    };

    // If we are trying to add a cart item we've seen before (that is, we are just updating from an edit) then we want to remove the old one and use the new one. This makes sure we regenerate an image thumbnail.
    if (cartItemId) {
      const cartItemIndex = staticCart.current.items.findIndex((ci: ICartItem) => ci.id === cartItemId);
      if (item !== null) {
        staticCart.current.items[cartItemIndex] = item;
        staticCart.current.items[cartItemIndex].previewImageUrl = previewImageUrl;

        // setCartContent({ ...cart });
        callback({ updatedCart: { ...staticCart.current }, newItem: { ...item } });
        return;
      }
    }

    // Otherwise, we just add a new cart item.
    const randomId = uuidv4();
    item.id = randomId;
    staticCart.current.items.push(item);

    // get index
    const cartItemIndex = staticCart.current.items.findIndex((ci: ICartItem) => ci.id === randomId);
    if (previewImageUrl) {
      // We do this async so that we can load the cart page without delay.
      // designApi.getMapThumbnailImageUrl(customProps).then(imageUrl => {
      staticCart.current.items[cartItemIndex].previewImageUrl = previewImageUrl;
    }

    // analytics
    analytics.addProductToCart(staticCart.current.items[cartItemIndex], shipping, false, orderId, customerId);
    trackAddProductToCart({ ...staticCart.current.items[cartItemIndex], previewImageUrl: null }, currency, productPriceCents);

    callback({ updatedCart: { ...staticCart.current }, newItem: { ...item } });
  };

  const addCartItemPlanets = (
    customProps: any,
    cartItemId: string,
    previewImageUrl: string,
    isCanvas: boolean,
    isFramed: boolean,
    isDigital: boolean,
    isPendant: boolean,
    pendantMaterial: string,
    frameColor: string,
    shipping: IShippingObject,
    orderId: string,
    customerId: string,
    isOrderPaid: boolean,
    currency: string,
    productPriceCents: number | null,
    callback: ({ updatedCart, newItem }: { updatedCart: ICart; newItem: ICartItem }) => void,
  ): void => {
    // Can't add items to paid orders.
    if (isOrderPaid) {
      return;
    }

    const item: ICartItem = {
      id: cartItemId || uuidv4(),
      copies: 1, // TODO: increment / decremenet functionality in staticCart.current.
      previewImageUrl, // TODO: also doesn't need to be here.
      design: {
        productType: "orrery",
        customProps,
      },
      frameColor: isFramed && frameColor && frameColor !== "" ? frameColor : "",
      isCanvas: isCanvas === true,
      isFramed: isFramed === true,
      isDigital: isDigital === true,
      isPendant: isPendant === true,
      sku: "",
      pendantMaterial,
    };

    // If we are trying to add a cart item we've seen before (that is, we are just updating from an edit) then we want to remove the old one and use the new one. This makes sure we regenerate an image thumbnail.
    if (cartItemId) {
      const cartItemIndex = staticCart.current.items.findIndex((ci: ICartItem) => ci.id === cartItemId);
      staticCart.current.items[cartItemIndex] = item;
      staticCart.current.items[cartItemIndex].previewImageUrl = previewImageUrl;

      callback({ updatedCart: { ...staticCart.current }, newItem: { ...item } });
      return;
    }

    // Otherwise, we just add a new cart item.
    const randomId = uuidv4();
    item.id = randomId;
    staticCart.current.items.push(item);

    const cartItemIndex = staticCart.current.items.findIndex((ci: ICartItem) => ci.id === randomId);
    if (previewImageUrl) {
      staticCart.current.items[cartItemIndex].previewImageUrl = previewImageUrl;
    }

    // analytics
    analytics.addProductToCart(staticCart.current.items[cartItemIndex], shipping, false, orderId, customerId);
    trackAddProductToCart({ ...staticCart.current.items[cartItemIndex], previewImageUrl: null }, currency, productPriceCents);

    callback({ updatedCart: { ...staticCart.current }, newItem: { ...item } });
  };

  const removeCartItem = (cartItemId: string, shipping: IShippingObject, orderId: string): void => {
    // Remove the cart item.
    const removedItems = staticCart.current.items.filter((item) => item.id === cartItemId);
    if (removedItems && removedItems.length > 0) {
      analytics.removeProductFromCart(removedItems[0], shipping, false, orderId);
      trackFbRemoveProductFromCart(removedItems[0], shipping, false, orderId);
    }

    const items: ICartItem[] = staticCart.current.items.filter((item) => item.id !== cartItemId);
    staticCart.current.items = items;
  };

  const removeCanvasImageFromCartItem = (cartItemId: string): void => {
    const cartItemIndex = staticCart.current.items.findIndex((ci: ICartItem) => ci.id === cartItemId);
    staticCart.current.items[cartItemIndex].previewImageUrl = undefined;

    // Copy the original list and insert the updated item.
  };

  const clearCartItems = (): void => {
    staticCart.current = { ...initCartObject };
  };

  const addFrameToCartItem = (
    cartItemId: string,
    frameColor: string,
    framingService: boolean,
    shipping: IShippingObject,
    isUpsell = false,
    orderId: string,
  ): ICartItem[] => {
    const cartItemIndex = staticCart.current.items.findIndex((ci: ICartItem) => ci.id === cartItemId);

    // Get a copy of the item.
    const cartItem = staticCart.current.items[cartItemIndex];
    const item = { ...cartItem };

    // analytics.removeProductFromCart(item, shipping, true);
    // trackFbRemoveProductFromCart(item, shipping, true);

    // Unset Canvas (if set)
    item.isCanvas = false;
    item.isDigital = false;
    item.isFramed = true;
    item.frameColor = frameColor;
    item.isUpsell = isUpsell;

    // Copy the original list and insert the updated item.
    staticCart.current.items[cartItemIndex] = { ...item, framingService };

    return staticCart.current.items;
  };

  // const removeFrameFromCartItem = (cartItemId: string, orderId: string, shipping: IShippingObject): void => {
  //   const cartItemIndex = staticCart.current.items.findIndex((ci: ICartItem) => ci.id === cartItemId);

  //   // Get a copy of the item.
  //   const cartItem = staticCart.current.items[cartItemIndex];
  //   const item = { ...cartItem };

  //   analytics.removeProductFromCart(item, shipping, true, orderId);
  //   trackFbRemoveProductFromCart(item, {}, true, orderId);

  //   item.isFramed = false;
  //   item.frameColor = null;

  //   // Copy the original list and insert the updated item.
  //   const items = [...cart.items];
  //   items[cartItemIndex] = item;

  //   setCartContent({ ...cart, items });
  // };

  const changeFrameColorForCartItem = (cartItemId: string, newFrameColor: string): void => {
    const cartItemIndex = staticCart.current.items.findIndex((ci: ICartItem) => ci.id === cartItemId);

    // Get a copy of the item.
    const cartItem = staticCart.current.items[cartItemIndex];
    const item = { ...cartItem };

    item.isCanvas = false;
    item.isDigital = false;
    item.isFramed = true;
    item.frameColor = newFrameColor;

    // Copy the original list and insert the updated item.
    staticCart.current.items[cartItemIndex] = item;
  };

  const addCanvasToCartItem = (cartItemId: string, shipping: IShippingObject, isUpsell = false, orderId: string): ICartItem[] => {
    const cartItemIndex = staticCart.current.items.findIndex((ci: ICartItem) => ci.id === cartItemId);

    // Get a copy of the item.
    const cartItem = staticCart.current.items[cartItemIndex];
    const item = { ...cartItem };

    // analytics.removeProductFromCart(item, shipping, true);
    // trackFbRemoveProductFromCart(item, shipping, true);

    // Unset Canvas (if set)
    item.isCanvas = true;
    item.isFramed = false;
    item.isDigital = false;
    item.frameColor = null;
    item.isUpsell = isUpsell;

    // Copy the original list and insert the updated item.
    staticCart.current.items[cartItemIndex] = { ...item };
    return staticCart.current.items;
  };

  // const removeCanvasFromCartItem = (cartItemId: string, shipping: IShippingObject, orderId: string): void => {
  //   const cartItemIndex = staticCart.current.items.findIndex((ci: ICartItem) => ci.id === cartItemId);

  //   // Get a copy of the item.
  //   const cartItem = staticCart.current.items[cartItemIndex];
  //   const item = { ...cartItem };

  //   analytics.removeProductFromCart(item, shipping, true, orderId);
  //   trackFbRemoveProductFromCart(item, {}, true, orderId);

  //   item.isCanvas = false;

  //   // Copy the original list and insert the updated item.
  //   const items = [...cart.items];
  //   items[cartItemIndex] = item;

  //   setCartContent({ ...cart, items });
  // };

  const setSizeIdForCartItem = (cartItemId: string, sizeId: string): ICartItem[] => {
    const cartItemIndex = staticCart.current.items.findIndex((ci: ICartItem) => ci.id === cartItemId);

    // Get a copy of the item.
    const cartItem = staticCart.current.items[cartItemIndex];
    const item = { ...cartItem };

    item.design.customProps.sizeId = sizeId;

    // Copy the original list and insert the updated item.
    staticCart.current.items[cartItemIndex] = { ...item };
    return staticCart.current.items;
  };

  // This is used for the situation when an edit is made from the staticCart.current.
  // Here, we pass back the id, do the edits, then the edits are applied to the correct cart item.
  const updateCartItem = (cartItem: ICartItem): void => {
    const cartItemIndex = staticCart.current.items.findIndex((ci: ICartItem) => ci.id === cartItem.id);
    staticCart.current.items[cartItemIndex] = cartItem;
  };

  const getAndUpdateCartPricing = async (
    shipping: IShippingObject,
    orderId: string,
    includeShippingUpgrades: boolean,
    includeItemUpgrades: boolean,
  ): Promise<ICart | null> => {
    try {
      const updatedCart = await orderApi.getCartWithPricing(
        staticCart.current,
        shipping,
        orderId,
        includeShippingUpgrades,
        includeItemUpgrades,
      );
      setCartContent({ ...updatedCart });
      return updatedCart;
    } catch (err) {
      console.error(`Could not get cart pricing ${err}`);
    }

    return null;
  };

  const getPricingForCartWithDiscount = async (
    shipping: IShippingObject,
    orderId: string,
    newDiscount: IDiscountObject,
  ): Promise<ICart | null> => {
    try {
      if (!shipping || !shipping.address || !newDiscount) {
        return null;
      }

      const { countryCode } = shipping.address;
      const newSizeMapping = getNewSize(countryCode);

      // Make sure sizes are correct for cart items & country code:
      staticCart.current = {
        ...staticCart.current,
        items: staticCart.current.items.map((item) => ({
          ...item,
          design: {
            ...item.design,
            customProps: {
              ...item.design.customProps,
              sizeId: newSizeMapping[item.design.customProps.sizeId] || item.design.customProps.sizeId,
            },
          },
        })),
      };

      //
      // Add discount to Cart Object
      if (!newDiscount.stackable) {
        // if not stackable, we get it in place without anything else
        staticCart.current = { ...staticCart.current, discount: { ...newDiscount, stacks: [] } };
      } else if (
        staticCart.current.discount &&
        staticCart.current.discount.stacks &&
        staticCart.current.discount.stacks.length > 0 &&
        staticCart.current.discount.stacks.find((coupon) => coupon.code === newDiscount.code)
      ) {
        // if coupon already added:
        const couponIndex = staticCart.current.discount.stacks.findIndex((coupon) => coupon.code === newDiscount.code);

        // we move to the head of the stack
        staticCart.current.discount.stacks.splice(couponIndex, 1);
        staticCart.current.discount.stacks.push(newDiscount);
      } else {
        // stackable and not there already, push it into the stack
        if (!staticCart.current.discount) {
          staticCart.current.discount = {};
        }
        if (!staticCart.current.discount.stacks) {
          staticCart.current.discount.stacks = [];
        }
        staticCart.current.discount.stacks.push({ ...newDiscount });
      }

      // Get pricing for the cart PLUS new discount:
      return orderApi.getCartWithPricing(staticCart.current, shipping, orderId, false, false);
    } catch (error) {
      console.error(`ERROR getting updated cart and pricing for discount code`); // eslint-disable-line no-console
    }

    return null;
  };

  const applyDiscountToCart = async (shipping: IShippingObject, orderId: string, newDiscount: IDiscountObject): Promise<ICart | null> => {
    try {
      // Store current values before we make any changes:
      const amountOffBeforeChange = staticCart.current.price ? staticCart.current.price.discountOffPrice || 0 : 0;

      // Get pricing with discount code (without applying it)
      const updatedCart = await getPricingForCartWithDiscount(shipping, orderId, newDiscount);
      if (updatedCart !== null) {
        if (
          amountOffBeforeChange === undefined ||
          updatedCart.price === undefined ||
          updatedCart.price.discountOffPrice === null ||
          updatedCart.price.discountOffPrice < amountOffBeforeChange
        ) {
          return null;
        }

        // The new discount is better (takes more off the price) than any existing one, so it
        // will replace the existing one - replace entire cart (inclyding pricing)
        setCartContent({ ...updatedCart });
        return updatedCart;

        // await this.saveDiscountToCart(newDiscount);
        // // Wait for cart state to update, then request pricing update
      }
    } catch (error) {
      console.error(`ERROR applying discount code to cart`); // eslint-disable-line no-console
    }

    return null;
  };

  const getPricingForCart = async (
    shipping: IShippingObject,
    orderId: string,
    previousAmountOff?: number,
    previousDiscount?: any,
  ): Promise<boolean> => {
    if (!shipping || !shipping.address) {
      return false;
    }

    const { countryCode } = shipping.address;
    const newSizeMapping = getNewSize(countryCode);

    setFetchingCartPricing(true);
    setFetchingCartPricingError("");

    staticCart.current.items = staticCart.current.items.map((item) => ({
      ...item,
      design: {
        ...item.design,
        customProps: {
          ...item.design.customProps,
          sizeId: newSizeMapping[item.design.customProps.sizeId] || item.design.customProps.sizeId,
        },
      },
    }));

    try {
      // const { total, individual, buyTwoDiscount } = await orderApi.getCartPricing(cart, shipping, orderId);
      const updatedCart = await orderApi.getCartWithPricing(staticCart.current, shipping, orderId, true, false);
      let discount = { ...updatedCart.discount };
      let { price } = updatedCart;

      if (price !== null) {
        if (
          previousAmountOff &&
          previousAmountOff > (price === undefined || price.discountOffPrice === null ? 0 : price.discountOffPrice)
        ) {
          discount = previousDiscount;
          price = updatedCart.price;
        }

        // BUY TWO
        // if (buyTwoDiscount && updatedCart.discount.discountCategory !== "giftCard") {
        //   if (!buyTwoDiscount.stackable) {
        //     if (discount.discountAmount < buyTwoDiscount.discountAmount) {
        //       discount = {
        //         ...buyTwoDiscount,
        //         stacks: [],
        //       };
        //     }
        //   } else if (discount.stacks.length === 0 || !discount.stacks.find((stackable) => stackable.code === "buytwo")) {
        //     discount = {
        //       ...discount,
        //       stacks: [...discount.stacks, buyTwoDiscount],
        //     };
        // }
      }

      setCartContent({ ...updatedCart, price, discount });
      setFetchingCartPricing(false);

      return (
        previousAmountOff !== undefined &&
        previousAmountOff > (price === undefined || price.discountOffPrice === null ? 0 : price.discountOffPrice)
      );
    } catch (err) {
      console.error(`Could not get cart pricing because of error ${err}`);
      setFetchingCartPricingError("Could not fetch cart pricing.");
      setFetchingCartPricing(false);
    }

    return false;
  };

  const loadCart = (cartParam: any): void => {
    const cartContents = cartParam._doc ? cartParam._doc : cartParam; // eslint-disable-line no-underscore-dangle
    setCartContent({ ...cartContents });
  };

  // const saveDiscountToCart = (saveDiscount: IDiscountObject): Promise<any> => {
  //   return new Promise((resolve, reject) => {
  //     try {
  //       if (!saveDiscount.stackable) {
  //         setCartContent({
  //           ...cart,
  //           discount: {
  //             ...saveDiscount,
  //             stacks: [], // clear stacks since not stackable
  //           },
  //         });
  //       } else {
  //         // setCartContent({
  //         const localCart = { ...cart };
  //         const isCouponAlreadyAdded =
  //           staticCart.current.discount.stacks &&
  //           staticCart.current.discount.stacks.length > 0 &&
  //           staticCart.current.discount.stacks.find((coupon) => coupon.code === `${saveDiscount.code}`);

  //         if (isCouponAlreadyAdded) {
  //           const couponIndex = staticCart.current.discount.stacks.findIndex((coupon) => coupon.code === saveDiscount.code);

  //           if (couponIndex >= 0) {
  //             localCart.discount.stacks.splice(couponIndex, 1);
  //             localCart.discount.stacks.push(saveDiscount);
  //             resolve({ cart: localCart });
  //           }
  //         }

  //         resolve({
  //           ...cart,
  //           discount: {
  //             ...cart.discount,
  //             stacks: [...cart.discount.stacks, { ...saveDiscount }],
  //           },
  //         });
  //       }
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // };

  const removeDiscountFromCart = async (newDiscount?: IDiscountObject, removedDiscount?: IDiscountObject): Promise<ICart> => {
    // todo
    if (removedDiscount) {
      let updatedDiscount = {};
      // let updatedPricing;

      const discountStacks = staticCart.current.discount && staticCart.current.discount.stacks ? staticCart.current.discount.stacks : [];
      if (removedDiscount.stacks) {
        updatedDiscount = {
          ...staticCart.current.discount,
          stacks: discountStacks.filter((c: any) => c.code !== removedDiscount.code),
        };
        // updatedPricing = {pricing: { ...this.state.cart.price, amountOff: this.state.cart.price.discountOffAmount - removedDiscount.discountAmount }};
      } else {
        updatedDiscount = {
          ...initCartObject.discount,
          stacks: discountStacks,
        };
      }

      staticCart.current = {
        ...staticCart.current,
        discount: updatedDiscount,
      };
    } else {
      staticCart.current = {
        ...staticCart.current,
        discount: newDiscount || initCartObject.discount,
      };
    }

    return staticCart.current;
  };

  const updatePhoneNumber = (phoneNumber: string) => {
    staticCart.current.phoneNumber = phoneNumber;
  };

  const increaseUpsellTotal = (upsellPrice: number) => {
    staticCart.current.upsellTotal = (staticCart.current.upsellTotal || 0) + upsellPrice;
  };

  const containsSomePendants = () => {
    return staticCart.current && staticCart.current.items && staticCart.current.items.some((x) => x.isPendant === true);
  };

  const containsOnlyPendants = () => {
    return (
      staticCart.current &&
      staticCart.current.items &&
      staticCart.current.items.length > 0 &&
      staticCart.current.items.every((x) => x.isPendant === true)
    );
  };

  // eslint-disable-next-line react/state-in-constructor
  const state = {
    // cart: initCartObject,
    // cart,
    staticCart,
    currentDesignCartItemId,
    fetchingCartPricing,
    fetchingCartPricingError,
    paypalScriptLoaded,
    loadCart,
    setPaypalScriptLoaded,
    addCartItem,
    addCartItemPlanets,
    removeCartItem,
    updateCartItem,
    setCurrentDesignCartItemId,
    getAndUpdateCartPricing,
    getPricingForCart,
    removeCanvasImageFromCartItem,
    clearCartItems,
    setCartContent,
    addFrameToCartItem,
    addCanvasToCartItem,
    // removeFrameFromCartItem,
    // removeCanvasFromCartItem,
    changeFrameColorForCartItem,
    // saveDiscountToCart,
    removeDiscountFromCart,
    updatePhoneNumber,
    clearCartContext,
    increaseUpsellTotal,
    setSizeIdForCartItem,
    applyDiscountToCart,
    shouldSubmitStripePaymentForm,
    setShouldSubmitStripePaymentForm,
    containsSomePendants,
    containsOnlyPendants,
  };

  return <Provider value={state}>{children}</Provider>;
};

const withCart = (Component) => {
  const WithCart = (props) => <Consumer>{(cartProps) => <Component {...cartProps} {...props} />}</Consumer>;

  WithCart.getInitialProps = async (context) => ({
    ...(Component.getInitialProps ? await Component.getInitialProps(context) : {}),
  });

  const displayName = Component.displayName || Component.name || `Unknown`;
  WithCart.displayName = `WithCart(${displayName})`;

  return WithCart;
};

export { CartProvider, Consumer as CartConsumer, CartContext, withCart };
