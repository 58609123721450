export const getNewSize = (countryCode: string) => {
  switch (countryCode.substring(0, 2)) {
    case "US":
    case "MX":
    case "CA":
    case "ZZ": // Unknown
    case "": // Unset
      return {
        "20x28": "18x24",
      };
    default:
      return { "18x24": "20x28" };
  }
};

export enum PwintyLabEnum {
  GLOBAL = "GLOBAL",
  GB = "GB",
  US = "US",
  AU = "AU",
}

/**
 * Get pwinty lab for order
 *
 * @param {*} shippingCountryCode The shipping country for the order
 * @param {*} framed Boolean true/false if the order is framed or not
 * @param {*} express Boolean true/false if the order is express
 */
const getProductShippingLab = (shippingCountryCode: string, framed: boolean, express: boolean) => {
  let pwintyLab = PwintyLabEnum.GLOBAL;

  // Fine Art Print	18" x 24"
  switch (shippingCountryCode.substring(0, 2)) {
    case "US": {
      if (framed === true && express === true) {
        pwintyLab = PwintyLabEnum.GB;
      }
      pwintyLab = PwintyLabEnum.US;
      break;
    }
    case "CA": {
      pwintyLab = PwintyLabEnum.GB;
      break;
    }
    case "GB": {
      pwintyLab = PwintyLabEnum.GB;
      break;
    }
    case "AU": {
      pwintyLab = PwintyLabEnum.AU;
      break;
    }
    case "NZ": {
      pwintyLab = PwintyLabEnum.AU;
      break;
    }
    case "MV": {
      // added 2019-09-08
      pwintyLab = PwintyLabEnum.AU;
      break;
    }
    case "MX": {
      // added 2019-09-08
      pwintyLab = PwintyLabEnum.US;
      break;
    }
    case "PL": // added 2019-09-08
    case "SI": // added 2019-09-08
    case "CH": // added 2019-09-08
    case "IM": // added 2019-09-08
    case "GG": // added 2019-09-08
    case "VA": // added 2019-09-08
    case "JE": {
      // added 2019-09-08
      pwintyLab = PwintyLabEnum.GB;
      break;
    }
    case "IE": {
      // added 2019-09-08
      if (framed === true && express === true) {
        pwintyLab = PwintyLabEnum.GB;
      } else {
        pwintyLab = PwintyLabEnum.GLOBAL;
      }
      break;
    }
    // https://mail.google.com/mail/u/1/#inbox/FMfcgxwDqxGNkJRJBPDsfLdbBJbCxCQb
    case "MY":
    case "KP":
    case "PH":
    case "SG":
    case "KR":
    case "TW":
    case "TH":
    case "AE":
    case "CN":
    case "HK":
    case "IN":
    case "ID":
    case "JP": {
      if (express === true) {
        pwintyLab = PwintyLabEnum.AU;
      } else {
        pwintyLab = PwintyLabEnum.GLOBAL;
      }
      break;
    }
    default:
      pwintyLab = PwintyLabEnum.GLOBAL;
      break;
  }

  return pwintyLab;
};

//  */
// const getSizeText = (type: string, sizeId: string, orientation: string, shippingCountryCode: string, item: any) => {
//   const { sku } = item;
//   const isFramed: boolean = item.isFramed === true;
//   const isCanvas: boolean = item.isCanvas === true;
//   const isDigital: boolean = item.isDigital === true;
//   const isExpress: boolean = item.isExpress === true;

//   return getSizeText(type, sizeId, orientation, shippingCountryCode, isFramed, isCanvas, isDigital, isExpress, sku);
// };

/**
 * Get's the size text for an order, paying attention to the metric/imperial output of each lab,
 * e.g. how GLOBAL_FAP_20x28 is 50x70cm in Europe (19.7 x 27.5") but 20"x28" (50x71 cm) from the USA lab!
 *
 */
export const getSizeText = (
  type: string,
  sizeId: string,
  orientation: string,
  shippingCountryCode: string,
  isFramed: boolean,
  isCanvas: boolean,
  isDigital: boolean,
  isExpress: boolean,
  sku: string,
): string => {
  // Digital Orders come in Multiple Sizes * shouldn't display a specific one
  if (isDigital) {
    return "";
  }

  let lab = getProductShippingLab(shippingCountryCode, isFramed || isCanvas, isExpress);
  // If SKU contains this, it's been forced to be a UK order
  if (sku && sku.indexOf(`-PRODIGI_GB3`) >= 0) {
    lab = PwintyLabEnum.GB;
  }

  let metric = false;
  switch (lab) {
    case PwintyLabEnum.GB:
      metric = true;
      break;
    case PwintyLabEnum.US:
      metric = false;
      break;
    case PwintyLabEnum.AU:
      metric = false;
      break;
    case PwintyLabEnum.GLOBAL:
    default:
      metric = true;
      break;
  }

  // //
  // // Exceptions:
  // //
  // if (sku.indexOf(`-AULEGACY`) >= 0) {
  //   // Australia changed to imperial sizes around August 2019, but we had been shipping a 50x70cm, which
  //   // suddenly became 51x71 (20x28" exactly), so they gave is AULEGACY codes, which ship in metric sizes,
  //   // so that e.g. "FAP-20X28-AULEGACY" is a 50x70cm print instead of 20x28"
  //   metric = true;
  // }

  // if (sku.indexOf(`-GBLEGACY`) >= 0) {
  //   // Used to send Imperoal sized orders from the GB lab (e.g. to Canada!); otherwise GB lab prints in metric
  //   metric = false;
  // }

  if (type === "print") {
    // EXCEPTIONS:
    if (shippingCountryCode === "CA") {
      if (["12x16", "18x24", "24x32"].indexOf(sizeId) >= 0) {
        // Canada from the UK uses the "GBLEGACY" code in these sizes
        metric = false;
      }
    }

    if (["AU", "NZ"].indexOf(shippingCountryCode) >= 0) {
      if (["20x28"].indexOf(sizeId) >= 0) {
        // 20x28 in Australia uses metric code
        metric = true;
      }
    }
  }

  // it would make more sense to have sizeId in the cart.items[].sizeId, but it's
  switch (sizeId) {
    case `12x12`: {
      return metric ? `30 x 30cm` : `12 x 12"`;
    }
    case `16x16`: {
      return metric ? `40 x 40cm` : `16 x 16"`;
    }
    case `12x16`: {
      if (orientation === "landscape") {
        return metric ? `40 x 30cm` : `16 x 12"`;
      }
      return metric ? `30 x 40cm` : `12 x 16"`;
    }
    case `18x24`: {
      if (orientation === "landscape") {
        return metric ? `60.9 x 45.7cm` : `24 x 18"`;
      }
      return metric ? `45.7 x 60.9cm` : `18 x 24"`;
    }
    case `24x32`: {
      if (orientation === "landscape") {
        return metric ? `80 x 60cm` : `32 x 24"`;
      }
      return metric ? `60 x 80cm` : `24 x 32"`;
    }
    case `20x28`: {
      if (orientation === "landscape") {
        return metric ? `70 x 50cm` : `28 x 20"`;
      }
      return metric ? `50 x 70cm` : `20 x 28"`;
    }
    // Legacy - 24x36 no longer provided
    case `24x36`: {
      if (orientation === "landscape") {
        return metric ? `90 x 60cm` : `36 x 24"`;
      }
      return metric ? `60 x 90cm` : `24 x 36"`;
    }
    // Legacy - 70x100 no longer provided
    case `28x40`: {
      if (orientation === "landscape") {
        return metric ? `100 x 70cm` : `40" x 28"`;
      }
      return metric ? `70 x 100cm` : `28" x 40"`;
    }

    default:
      return sizeId;
  }
};

/**
 * Get's the size text for an order, paying attention to the metric/imperial output of each lab,
 * e.g. how GLOBAL_FAP_20x28 is 50x70cm in Europe (19.7 x 27.5") but 20"x28" (50x71 cm) from the USA lab!
 *
 * @param sku
 * @param shippingCountryCode
 * @param framed
 * @param express
 */
export const getSizeTextForSizeCountryCode = (
  sku: string,
  shippingCountryCode: string,
  sizeId: string,
  orientation: string,
  isFramed: boolean,
  isCanvas: boolean,
  isDigital: boolean,
  isPendant: boolean,
  isExpress: boolean,
): string => {
  // Digital Orders come in Multiple Sizes * shouldn't display a specific one
  if (isDigital) {
    return "";
  }

  if (isPendant) {
    return "";
  }

  let lab = getProductShippingLab(shippingCountryCode, isFramed || isCanvas, isExpress);

  // If SKU contains this, it's been forced to be a UK order
  if (sku && sku.indexOf(`-PRODIGI_GB3`) >= 0) {
    lab = PwintyLabEnum.GB;
  }

  let metricLab = false;
  switch (lab) {
    case PwintyLabEnum.GB:
      metricLab = true;
      break;
    case PwintyLabEnum.US:
      metricLab = false;
      break;
    case PwintyLabEnum.AU:
      metricLab = false;
      break;
    case PwintyLabEnum.GLOBAL:
    default:
      metricLab = true;
      break;
  }

  let metricLocation = true;
  switch (shippingCountryCode) {
    case "US":
    case "CA":
    case "MX":
    case "AU":
    case "NZ": {
      metricLocation = false;
      break;
    }
    default: {
      metricLocation = true;
      break;
    }
  }

  //
  // Exceptions:
  //
  if (sku && sku.indexOf(`-AULEGACY`) >= 0) {
    // Australia changed to imperial sizes around August 2019, but we had been shipping a 50x70cm, which
    // suddenly became 51x71 (20x28" exactly), so they gave is AULEGACY codes, which ship in metric sizes,
    // so that e.g. "FAP-20X28-AULEGACY" is a 50x70cm print instead of 20x28"
    metricLab = true;
  }

  if (sku && sku.indexOf(`-GBLEGACY`) >= 0) {
    // Used to send Imperoal sized orders from the GB lab (e.g. to Canada!); otherwise GB lab prints in metric
    metricLab = false;
  }

  // if (type === "print") {
  const isPrint = !isFramed && !isCanvas && !isDigital;
  if (isPrint) {
    if (shippingCountryCode === "CA") {
      if (["12x16", "18x24", "24x32"].indexOf(sizeId) >= 0) {
        // Canada from the UK uses the "GBLEGACY" code in these sizes
        metricLab = false;
      }
    }

    if (["AU", "NZ"].indexOf(shippingCountryCode) >= 0) {
      if (["20x28"].indexOf(sizeId) >= 0) {
        // 20x28 in Australia uses metric code
        metricLab = true;
      }
    }
  }

  // it would make more sense to have sizeId in the cart.items[].sizeId, but it's
  switch (sizeId) {
    case `12x12`: {
      if (metricLocation) {
        return metricLab || isPrint ? `30 x 30cm` : `30.5 x 30.5cm`;
      }

      return metricLab && isPrint ? `11.8 x 11.8"` : `12 x 12"`;
    }
    case `16x16`: {
      if (metricLocation) {
        return metricLab || isPrint ? `40 x 40cm` : `40.6 x 40.6cm`;
      }
      return metricLab && isPrint ? `15.7 x 15.7"` : `16 x 16"`;
    }
    case `12x16`: {
      if (metricLocation) {
        if (orientation === "landscape") {
          return metricLab || isPrint ? `40 x 30cm` : `40.6 x 30.5cm"`;
        }
        return metricLab || isPrint ? `30 x 40cm` : `30.5 x 40.6cm`;
      }

      if (orientation === "landscape") {
        return metricLab && isPrint ? `15.7 x 11.8"` : `16 x 12"`;
      }
      return metricLab && isPrint ? `11.8 x 15.7"` : `12 x 16"`;
    }
    case `18x24`: {
      if (metricLocation) {
        if (orientation === "landscape") {
          return metricLab || isPrint ? `60 x 45cm` : `60.9 x 45.7cm`;
        }
        return metricLab || isPrint ? `45 x 60cm` : `45.7 x 60.9cm`;
      }

      if (orientation === "landscape") {
        return metricLab && isPrint ? `23.6 x 17.7"` : `24 x 18"`;
      }
      return metricLab && isPrint ? `17.7 x 23.6"` : `18 x 24"`;
    }
    case `24x32`: {
      if (metricLocation) {
        if (orientation === "landscape") {
          return metricLab || isPrint ? `80 x 60cm` : `81.3 x 61cm`;
        }
        return metricLab || isPrint ? `60 x 80cm` : `61 x 81.3cm`;
      }
      if (orientation === "landscape") {
        return metricLab && isPrint ? `31.5 x 23.6"` : `32 x 24"`;
      }
      return metricLab && isPrint ? `23.6 x 31.5"` : `24 x 32"`;
    }
    case `20x28`: {
      if (metricLocation) {
        if (orientation === "landscape") {
          return metricLab || isPrint ? `70 x 50cm` : `71.1 x 50.8cm`;
        }
        return metricLab || isPrint ? `50 x 70cm` : `50.8 x 71.1cm`;
      }
      if (orientation === "landscape") {
        return metricLab && isPrint ? `27.5 x 19.7"` : `28 x 20"`;
      }
      return metricLab && isPrint ? `19.7 x 27.5"` : `20 x 28"`;
    }
    case `24x36`: {
      // Legacy - 24x36 no longer provided
      if (metricLocation) {
        if (orientation === "landscape") {
          return metricLab || isPrint ? `90 x 60cm` : `91.4 x 61cm`;
        }
        return metricLab || isPrint ? `60 x 90cm` : `61 x 91.4cm`;
      }
      if (orientation === "landscape") {
        return metricLab && isPrint ? `35.4 x 23.6"` : `36 x 24"`;
      }
      return metricLab && isPrint ? `23.6 x 35.4"` : `24 x 36"`;
    }
    case `28x40`: {
      // Legacy - 70x100 no longer provided
      if (metricLocation) {
        if (orientation === "landscape") {
          return metricLab || isPrint ? `100 x 70cm` : `101.6 x 71.1cm`;
        }
        return metricLab || isPrint ? `70 x 100cm` : `71.1 x 101.6cm`;
      }
      if (orientation === "landscape") {
        return metricLab && isPrint ? `39.4 x 27.5"` : `40 x 28"`;
      }
      return metricLab && isPrint ? `27.5 x 39.4"` : `28" x 40"`;
    }

    default:
      return sizeId;
  }
};
